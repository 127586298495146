
import {
  Vue,
  Component,
  InjectReactive,
  Mixins,
  Inject,
} from "vue-property-decorator";
import TopAppBar from "@/components/base/TopAppBar.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import gql from "graphql-tag";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import Price from "@/components/base/Price.vue";
import PromoCodeMixin from "@/mixins/promo-code.mixin";

const TELEGRAM_SUBSCRIBE_KEY = "TELEGRAM_SUBSCRIBE_KEY";

@Component({
  components: {
    TopAppBar,
    GradientBtn,
    Price,
  },
})
export default class BonusPage extends Mixins(PromoCodeMixin) {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  telegramLink = "https://t.me/buyskinsru";
  loading = false;
  isTelegramSubscribed = false;
  getAmount = 999999;

  created() {
    if (!this.user) {
      this.$router.replace("/#login");
    }

    this.isTelegramSubscribed = this.$localStorage.get(
      TELEGRAM_SUBSCRIBE_KEY,
      false
    );
  }

  get telegramBonusDeposit() {
    const needToDeposit = Number((100 - this.user.depositsSum).toFixed(2));

    return needToDeposit > 0 ? needToDeposit : 0;
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async subscribeTelegram() {
    this.isTelegramSubscribed = true;
    this.$localStorage.set(TELEGRAM_SUBSCRIBE_KEY, true);
  }

  async getTelegramBonus() {
    this.loading = true;
    await this.sleep(5000);

    await this.$apollo.mutate({
      mutation: gql`
        mutation {
          getTelegramBonus
        }
      `,
    });

    this.loading = false;
  }
}
